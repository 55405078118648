import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47478bbd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_services_byoc_dropdown = _resolveComponent("services-byoc-dropdown")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, null, {
    "body-cell-provider-slot": _withCtx((props) => [
      _createVNode(_component_router_link, {
        class: "emphasize--text blue-on-hover font-weight-normal",
        to: props.row.provider.link
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              class: "logo-item",
              src: props.row.provider.logo
            }, null, 8, _hoisted_2),
            _createElementVNode("span", null, _toDisplayString(props.row.provider.name), 1),
            (props.row.status.name !== 'connected')
              ? (_openBlock(), _createBlock(_component_tm_icon, {
                  key: 0,
                  name: "warning",
                  class: "warning--text ml-1",
                  size: "xLarge"
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-actions": _withCtx((props) => [
      _createVNode(_component_services_byoc_dropdown, {
        link: props.row.provider.link
      }, null, 8, ["link"])
    ]),
    "body-cell-status-slot": _withCtx((props) => [
      _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(props.row.status)), null, 16)
    ]),
    _: 1
  }))
}