import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_byoc_providers_empty = _resolveComponent("byoc-providers-empty")!
  const _component_services_byoc_filter = _resolveComponent("services-byoc-filter")!
  const _component_services_byoc_table = _resolveComponent("services-byoc-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isEmptyMode)
      ? (_openBlock(), _createBlock(_component_byoc_providers_empty, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_services_byoc_filter, {
            search: _ctx.search,
            "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            "search-placeholder": "Search providers",
            class: "mb-4"
          }, null, 8, ["search"]),
          _createVNode(_component_services_byoc_table, {
            headers: _ctx.tableHeaders,
            items: _ctx.tableItems,
            "show-selection": false,
            "items-name": "providers",
            "show-link": ""
          }, null, 8, ["headers", "items"])
        ], 64))
  ]))
}