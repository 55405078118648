
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import ServicesByocDropdown from '@/components/pages/services/byoc/list/ServicesByocDropdown.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import { useByoc } from '@/compositions/services/useByoc'

export default defineComponent({
  components: {
    TmTable,
    ServicesByocDropdown,
    TmStatus,
  },
  setup() {
    const { openEditLabelModal } = useByoc()

    const handleEditClick = () => {
      openEditLabelModal()
    }

    return {
      handleEditClick,
    }
  },
})
