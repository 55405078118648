
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmEmptyState from '@/components/shared/states/TmEmptyState.vue'
import TmWatchTutorial from '@/components/shared/TmWatchTutorial.vue'

export default defineComponent({
  components: {
    TmButton,
    TmEmptyState,
    TmWatchTutorial,
  },
})
